/* Dropdown Menu */
.vs__dropdown-menu {
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    position: absolute;
    /* calc to ensure the left and right borders of the dropdown appear flush with the toggle. */
    top: calc(100% - var(--vs-border-width));
    left: 0;
    z-index: var(--vs-dropdown-z-index);
    padding: 5px 0;
    margin: 0;
    width: 100%;
    max-height: var(--vs-dropdown-max-height);
    min-width: var(--vs-dropdown-min-width);
    overflow-y: auto;
    -webkit-box-shadow: var(--vs-dropdown-box-shadow);
            box-shadow: var(--vs-dropdown-box-shadow);
    border: var(--vs-border-width) var(--vs-border-style) var(--vs-border-color);
    border-top-style: none;
    border-radius: 0 0 var(--vs-border-radius) var(--vs-border-radius);
    text-align: left;
    list-style: none;
    background: var(--vs-dropdown-bg);
    color: var(--vs-dropdown-color);
}
.vs__no-options {
    text-align: center;
}
