/**
    Dropdown Toggle

    The dropdown toggle is the primary wrapper of the component. It
    has two direct descendants: .vs__selected-options, and .vs__actions.

    .vs__selected-options holds the .vs__selected's as well as the
    main search input.

    .vs__actions holds the clear button and dropdown toggle.
 */
.vs__dropdown-toggle {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 0 0 4px 0;
    background: var(--vs-search-input-bg);
    border: var(--vs-border-width) var(--vs-border-style) var(--vs-border-color);
    border-radius: var(--vs-border-radius);
    white-space: normal;
}
.vs__selected-options {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    padding: 0 2px;
    position: relative;
}
.vs__actions {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: var(--vs-actions-padding);
}

/* Dropdown Toggle States */
.vs--searchable .vs__dropdown-toggle {
    cursor: text;
}
.vs--unsearchable .vs__dropdown-toggle {
    cursor: pointer;
}
.vs--open .vs__dropdown-toggle {
    border-bottom-color: transparent;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
