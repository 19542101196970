/* Loading Spinner */
.vs__spinner {
    -ms-flex-item-align: center;
        align-self: center;
    opacity: 0;
    font-size: 5px;
    text-indent: -9999em;
    overflow: hidden;
    border-top: 0.9em solid rgba(100, 100, 100, 0.1);
    border-right: 0.9em solid rgba(100, 100, 100, 0.1);
    border-bottom: 0.9em solid rgba(100, 100, 100, 0.1);
    border-left: 0.9em solid rgba(60, 60, 60, 0.45);
    -webkit-transform: translateZ(0)
        scale(var(--vs-controls--spinner-size, var(--vs-controls-size)));
            transform: translateZ(0)
        scale(var(--vs-controls--spinner-size, var(--vs-controls-size)));
    -webkit-animation: vSelectSpinner 1.1s infinite linear;
            animation: vSelectSpinner 1.1s infinite linear;
    -webkit-transition: opacity 0.1s;
    transition: opacity 0.1s;
}
.vs__spinner,
.vs__spinner:after {
    border-radius: 50%;
    width: 5em;
    height: 5em;
    -webkit-transform: scale(var(--vs-controls--spinner-size, var(--vs-controls-size)));
            transform: scale(var(--vs-controls--spinner-size, var(--vs-controls-size)));
}

/* Loading Spinner States */
.vs--loading .vs__spinner {
    opacity: 1;
}
